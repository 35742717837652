import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const BuyProperty = () => {
  const [paymentMethod, setPaymentMethod] = useState('Paypal'); // Default payment method
  const [purchaseDetails, setPurchaseDetails] = useState(null); // To store the purchase details from localStorage
  const apiUrl = process.env.REACT_APP_API_URL;
  const user = useSelector((state) => state.auth.user);
  const { id: userId } = user.claims;
  const navigate = useNavigate();

  // Fetch purchase details from localStorage on component mount
  useEffect(() => {
    const storedDetails = JSON.parse(localStorage.getItem('purchaseDetails'));
    if (storedDetails) {
      setPurchaseDetails(storedDetails);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!purchaseDetails) {
      console.error('No purchase details found in localStorage.');
      return;
    }

    // Fill the transaction object with the data from purchaseDetails and additional fields
    const transaction = {
      ForSaleId: purchaseDetails.ForSaleId,
      InvestorId: userId, // Using the logged-in userId
      PropertyId: purchaseDetails.PropertyId,
      SellerId: userId,
      Shares: purchaseDetails.Shares,
      SellerFullName: purchaseDetails.SellerFullName,
      Amount: purchaseDetails.AmountTannit,
      PaymentMethod: paymentMethod,
      TypeTransaction: "selling-marketPlace",
    };

    try {
      if (paymentMethod === 'Paypal' || paymentMethod === 'Stripe') {
        const response = await axios.post(`${apiUrl}/Marketplace/Create`, transaction);
        if (response.status === 201 || response.status === 200) {
          navigate('/buyPropertySuccess');
        } else {
          console.error('Failed to add transaction:', response.data);
        }
      } else if (paymentMethod === 'GoCardless') {
        navigate('/topupFailer');
      }
    } catch (error) {
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
        navigate('/topupFailer');
      } else if (error.request) {
        console.error('Error request:', error.request);
        navigate('/topupFailer');
      } else {
        console.error('Error message:', error.message);
        navigate('/topupFailer');
      }
    }
  };

  useEffect(() => {
    document.title = 'Add money'; // Set the page title on component mount
    return () => {
      document.title = 'Tannit'; // Reset the page title when component unmounts
    };
  }, []);

  return (
    <div className="App">
      <section className="main-cont-comp" style={{ maxWidth: '800px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 main-comp-sec">
              {/* Use the PropertyId and SellerFullName from purchaseDetails */}
              <h3>Buy {purchaseDetails ? purchaseDetails.SellerFullName : ''}'s investment: Property ID {purchaseDetails ? purchaseDetails.PropertyId : ''}</h3>
              <form onSubmit={handleSubmit}>
                <div className="col-12">
                  <div className="mr-1 row d-flex">
                    <div className="form-radio-check col-4">
                      <label className="form-check-label" htmlFor="flexRadioDefault1"></label>
                      <h4>
                        <span>
                          <img src="assets/icons/Tannit coin.svg" alt="" />
                        </span>
                        {/* Display the AmountTannit from purchaseDetails */}
                        {purchaseDetails ? purchaseDetails.Shares : ''}
                      </h4>
                      {/* Replace hardcoded amount with AmountTannit value */}
                      <p>{purchaseDetails ? `${purchaseDetails.AmountTannit} £` : 'N/A'}</p>
                    </div>
                    <div className="col-12 new-radio-2">
                      <h3>Payment method</h3>
                      <div className="mr-1 row d-flex">
                        <div className="form-radio-check2 col-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="flexRadioDefault2_1"
                            onChange={() => setPaymentMethod('Stripe')}
                            checked={paymentMethod === 'Stripe'}
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault2_1"></label>
                          <h4>
                            <span>
                              <img src="assets/icons/stripe.svg" alt="" />
                            </span>
                            Stripe
                          </h4>
                        </div>
                        <div className="form-radio-check2 col">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="flexRadioDefault2_2"
                            onChange={() => setPaymentMethod('Paypal')}
                            checked={paymentMethod === 'Paypal'}
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault2_2"></label>
                          <h4>
                            <span>
                              <img src="assets/icons/paypal.svg" alt="" />
                            </span>
                            Paypal
                          </h4>
                        </div>
                        <div className="form-radio-check2 col-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="flexRadioDefault2_3"
                            onChange={() => setPaymentMethod('GoCardless')}
                            checked={paymentMethod === 'GoCardless'}
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault2_3"></label>
                          <h4>
                            <span>
                              <img src="assets/icons/cardless.svg" alt="" />
                            </span>
                            GoCardless
                          </h4>
                        </div>
                        <div className="verification-btns d-flex">
                          <button type="button" className="btn radio-btn-1 btn-primary" onClick={() => navigate(-1)}>
                            Cancel
                          </button>
                          <button type="submit" className="btn radio-btn-2 btn-primary">
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BuyProperty;
