import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../component/dashheader';
import Range from '../../component/price-range';
import { useParams, useNavigate } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Mgallery from '../../component/property-card';
import { fetchPropertyDetails,fetchPreviousProperty,fetchNextProperty, clearPropertyDetails, fetchPropertyInvestors } from '../../features/properties/propertySlice';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';


const initialPropertyDetails = {
  propertyId: 0,
  propertyName: '',
  price: 0,
  createdAt: '',
  updatedAt: '',
  archived: false,
  propertyDescription: '',
  propertyTypeId: 0,
  propertyTypeName: '',
  address: {
    addressId: 0,
    latitude: 0,
    longitude: 0,
    streetAddress: '',
    streetAddress2: '',
    streetNumber: 0,
    buildingNumber: '',
    appartNumber: 0,
    city: '',
    state: '',
    countryId: 0,
    postalCode: ''
  },
  images: [],
  tags: []
};
const initialPropertyInvestors = {
  propertyId: 0,
  numberOfInvestors: 0,
  propertyPrice: 0,
  totalTannitInvested: 0,

};
const sanitizePropertyDetails = (property) => {
  return {
    propertyId: property.propertyId || 0,
    propertyName: property.propertyName || '',
    price: property.price || 0,
    createdAt: property.createdAt || '',
    updatedAt: property.updatedAt || '',
    archived: property.archived || false,
    propertyDescription: property.propertyDescription || '',
    propertyTypeId: property.propertyTypeId || 0,
    propertyTypeName: property.propertyTypeName || '',
    address: {
      addressId: property.address?.addressId || 0,
      latitude: property.address?.latitude || 0,
      longitude: property.address?.longitude || 0,
      streetAddress: property.address?.streetAddress || '',
      streetAddress2: property.address?.streetAddress2 || '',
      streetNumber: property.address?.streetNumber || 0,
      buildingNumber: property.address?.buildingNumber || '',
      appartNumber: property.address?.appartNumber || 0,
      city: property.address?.city || '',
      state: property.address?.state || '',
      countryId: property.address?.countryId || 0,
      postalCode: property.address?.postalCode || ''
    },
    images: property.images || [],
    tags: property.tags || []
  };
};
const sanitizePropertyInvestors = (investors) => {
  return {
    propertyId: investors.propertyId || 0,
    numberOfInvestors: investors.numberOfInvestors || 0,
    propertyPrice: investors.propertyPrice || 0,
    totalTannitInvested: investors.totalTannitInvested || 0,
  };
};
const PropertyDetail = () => {
  useEffect(() => {
    document.title = 'Property details'; // Set the page title on component mount
    return () => {
      document.title = 'Tannit'; // Reset the page title when component unmounts
    };
  }, []); // Empty dependency array ensures this effect runs only on mount and unmount

  const { propertyId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const propertyDetails = useSelector((state) => state.property.propertyDetails);
  const propertyInvestorsInt = useSelector((state) => state.property.propertyInvestors);
  const { previousProperty, nextProperty } = useSelector((state) => state.property);
  
 
  const propertyInvestors =
    propertyInvestorsInt &&
      propertyInvestorsInt.length > 0 &&
      Array.isArray(propertyInvestorsInt[0]) &&
      propertyInvestorsInt[0].length > 0
      ? sanitizePropertyInvestors(propertyInvestorsInt[0][0])
      : initialPropertyInvestors;

  const status = useSelector((state) => state.property.status);
  const error = useSelector((state) => state.property.error);


  const sanitizedPropertyDetails = sanitizePropertyDetails(
    propertyDetails && propertyDetails.length ? propertyDetails[0] : initialPropertyDetails
  );
  const addressString = `${sanitizedPropertyDetails?.address?.streetAddress || ''}, ${sanitizedPropertyDetails?.address?.city || ''} ${sanitizedPropertyDetails?.address?.postalCode || ''}`;

  const [open, setOpen] = useState(false);

  const images = sanitizedPropertyDetails.images.map((image) => ({
    type: image.type, // type can be "image" or "video"
    src: image.url,
    alt: "Property Image",
  }));

  const showMoreImages = () => {
    setOpen(true);
  };

  const closeLightbox = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(fetchPropertyDetails(propertyId));
    dispatch(fetchPropertyInvestors(propertyId));
    dispatch(fetchPreviousProperty(propertyId));
    dispatch(fetchNextProperty(propertyId));

    return () => {
      dispatch(clearPropertyDetails());
    };
  }, [dispatch, propertyId]);
  const handlePreviousPropertyClick = () => {
    if (previousProperty) {
      navigate(`/propertydetail/${previousProperty.propertyId}`);
    } else {
      navigate('/properties');
    }
  };
  const handleNextPropertyClick = () => {
    if (nextProperty) {
      navigate(`/propertydetail/${nextProperty.propertyId}`);
    } else {
      navigate('/properties');
    }
  };
  const price = sanitizedPropertyDetails.price;
  const priceInTannit = price / 10;
  const numberOfInvestors = propertyInvestors.numberOfInvestors;
  const totalTannitInvested = propertyInvestors.totalTannitInvested
  const remainingInvested = priceInTannit - totalTannitInvested;
  const progress = ((priceInTannit - remainingInvested) / priceInTannit) * 100;
  const settings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const renderSlide = ({ slide }) => {
    if (slide.type === 'video') {
      return (
        <video controls style={{ maxWidth: '100%', maxHeight: '100%' }}>
          <source src={slide.src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return <img src={slide.src} alt="Property Media" style={{ maxWidth: '100%', maxHeight: '100%' }} />;
  };


  const [map, setMap] = useState(null);
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const address = "Orange street, United Kingdom 5012";
  const apiKey = "AIzaSyCWhL4ncu9oyBH3HEG3bRx8dVYdcy9ClH8"; // Replace with your Google Maps API key
  const center = { lat: 51.5074, lng: -0.1278 }; // Coordinates for London, UK

  useEffect(() => {
    if (map) loadPlaces(map);
  }, [map]);

  const loadPlaces = (map) => {
    const service = new window.google.maps.places.PlacesService(map);
    const request = {
      location: center,
      radius: '2000', // Adjust radius as needed
      type: ['restaurant', 'train_station', 'school', 'hotel', 'cafe', 'bank'] // Specify the types of places you're interested in
    };
    service.nearbySearch(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setPlaces(results);
      } else {
        console.error('Places service error:', status);
      }
    });
  };

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'error') {
    return <div>Error: {error.message}</div>;
  }

  if (!propertyDetails || propertyDetails.length === 0) {
    return <div>No property details found.</div>;
  }
  const gainTags = sanitizedPropertyDetails.tags.filter(tag => tag.section === "Gain");

  // Find the specific tags for "Target Profitability" and "Returned"
  const profitabilityTag = gainTags.find(tag => tag.label === "Target Profitability");
  const returnedTag = gainTags.find(tag => tag.label === "Returned Income");
  return (
    <div className="App">
      <Header />
      <section className="below-main">
      <div className="container-fluid artical-cont" style={{ maxWidth: '1440px' }}>
        <div className="row artical2 d-flex">
          
          <div className="tab-btns2 d-flex flex-md-row flex-column my-1 px-2">
          <div className="col-md-10 col-12 d-flex justify-content-around">
            <div className="top-head" onClick={handlePreviousPropertyClick}>
              <img src="/assets/icons/Iconleft.svg" alt="/" />
              {previousProperty ? previousProperty.propertyName : 'Return to properties'}
            </div>
            <div className="top-head" onClick={handleNextPropertyClick}>
              {nextProperty ? nextProperty.propertyName : 'Next Property'}
              <i style={{ marginLeft: '7px', marginTop: '3px' }} class="fa-solid fa-arrow-right" aria-hidden="true"></i>
            </div>
            </div>
            <div className="col-md-2 col-12" style={{ textAlign: 'center' }}>
              <button className="btn-5 tb-2 p-2">
                <img src="/assets/icons/heart.svg" alt="/" />
              </button>
              <button className="btn-5 tb-2 p-2">
                <img src="/assets/icons/share.svg" alt="/" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
      <section>
        <div className="container fullw-li">
          <Slider {...settings}>
            <div className="list-group-item"><span>£921</span> Rental income</div>
            <div className="list-group-item"><span>6.5%</span> Gross yield</div>
            <div className="list-group-item"><span>£42.500</span> Minimum deposit</div>
            <div className="list-group-item"><span>8.5%</span> Rental growth</div>
            <div className="list-group-item"><span>3.0%</span> Cap growth</div>
            <div className="list-group-item"><span>£921</span> Rental income</div>
            <div className="list-group-item"><span>6.5%</span> Gross yield</div>
            <div className="list-group-item"><span>£42.500</span> Minimum deposit</div>
            <div className="list-group-item"><span>8.5%</span> Rental growth</div>
            <div className="list-group-item"><span>3.0%</span> Cap growth</div>
          </Slider>
        </div>
      </section>
      <div className="container-fluid artical-cont" style={{ 'maxWidth': '1440px' }}>
        <div className="row sec-rpd">
          <div className="col-sm-8 p-4">
            <div className="container">
              <div className="row">
                {sanitizedPropertyDetails.images.slice(0, 3).map((image, index) => (
                  <div key={index} className="col-lg-6 img-width img-col d-flex justify-content-center mb-3">
                    <img src={image.url} alt={`Image ${index + 1}`} />
                  </div>
                ))}
                <div className="col img-col bt-box">
                  <button onClick={showMoreImages} className="img-btn">
                    <img src="/assets/icons/gallery.svg" alt="Gallery Icon" />
                    Show More Images
                  </button>
                </div>
              </div>
              {open && (
                <Lightbox
                  open={open}
                  close={closeLightbox}
                  slides={images}
                  render={{ slide: renderSlide }}
                />
              )}
            </div>
            <div className="row next-row my-4">
              <div className="tabs-section d-flex justify-content-start pdp-page" >
                <ul className="nav nav-tabs d-flex justify-content-start pdp-tab" id="myTab" role="tablist">
                  <li className="nav-item new-ptab" role="presentation">
                    <button className="nav-link active" id="General-tab" data-bs-toggle="tab" data-bs-target="#General"
                      type="button" role="tab" aria-controls="General" aria-selected="true">General</button>
                  </li>
                  <li className="nav-item new-ptab" role="presentation">
                    <button className="nav-link" id="Sustainability-tab" data-bs-toggle="tab" data-bs-target="#Sustainability" type="button"
                      role="tab" aria-controls="Sustainability" aria-selected="false">Sustainability</button>
                  </li>
                  <li className="nav-item  new-ptab" role="presentation">
                    <button className="nav-link" id="Place-tab" data-bs-toggle="tab" data-bs-target="#Place" type="button"
                      role="tab" aria-controls="Place" aria-selected="false">Place</button>
                  </li>
                  <li className="nav-item new-ptab" role="presentation">
                    <button className="nav-link" id="Finance-tab" data-bs-toggle="tab" data-bs-target="#Finance" type="button"
                      role="tab" aria-controls="Finance" aria-selected="false">Finance</button>
                  </li>

                </ul>
              </div>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade active show " id="General" role="tabpanel" aria-labelledby="General-tab">
                  <div className="tab-inner">
                    <h1>{sanitizedPropertyDetails.propertyName}</h1>
                    <p className="card-text"><span><img style={{ paddingRight: '5px' }} src="/assets/icons/gb 1.svg" alt="/" /></span>
                      {addressString}
                    </p>
                    <h5>Description</h5>
                    <p>{sanitizedPropertyDetails.propertyDescription}</p>

                  </div>
                  <div className="tab-inner2">
                    <h5>Property details</h5>
                    <div className="row pd-tr">
                      {sanitizedPropertyDetails.tags.filter(tag => tag.section === "Property details").map((tag, index) => (
                        <React.Fragment key={tag.tagId}>
                          <div className="col-md-4 col-3 p-detail">
                            <p>{tag.label} <span>{tag.decimalValue} {tag.unit}</span></p>
                          </div>
                          
                        </React.Fragment>
                      ))}
                    </div>

                    <h5 className='pd-tr-hd'>Energy</h5>
                  </div>


                  <div className="tab-inner3">
                    <div className="row">
                      <div className="col ptab-detail">
                        {sanitizedPropertyDetails.tags.filter(tag => tag.section === "Energy").map(tag => (
                          <p key={tag.tagId}>Energy Class: <span>{tag.label}</span></p>
                        ))}
                      </div>
                      <div className="col ptab-detail">
                        {sanitizedPropertyDetails.tags.filter(tag => tag.section === "Energy").map(tag => (
                          <p key={tag.tagId}>Energy Index in KWh/m2a: <span>{tag.decimalValue}</span></p>
                        ))}
                      </div>
                      <div className="color-tabs">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                          <li className="nav-item item1" role="presentation">
                            <button disabled className={`nav-link ${sanitizedPropertyDetails.tags.some(tag => tag.label === 'A') ? 'active' : ''}`} id="pills-a-tab" data-bs-toggle="pill"
                              data-bs-target="#pills-a" type="button" role="tab"
                              aria-controls="pills-a" aria-selected="true">A</button>
                          </li>
                          <li className="nav-item item2" role="presentation">
                            <button disabled className={`nav-link ${sanitizedPropertyDetails.tags.some(tag => tag.label === 'B') ? 'active' : ''}`} id="pills-b-tab" data-bs-toggle="pill"
                              data-bs-target="#pills-b" type="button" role="tab"
                              aria-controls="pills-b" aria-selected="false">B</button>
                          </li>
                          <li className="nav-item item3" role="presentation">
                            <button disabled className={`nav-link ${sanitizedPropertyDetails.tags.some(tag => tag.label === 'C') ? 'active' : ''}`} id="pills-c-tab" data-bs-toggle="pill"
                              data-bs-target="#pills-c" type="button" role="tab"
                              aria-controls="pills-c" aria-selected="false">C</button>
                          </li>
                          <li className="nav-item item4" role="presentation">
                            <button disabled className={`nav-link ${sanitizedPropertyDetails.tags.some(tag => tag.label === 'D') ? 'active' : ''}`} id="pills-d-tab" data-bs-toggle="pill"
                              data-bs-target="#pills-d" type="button" role="tab"
                              aria-controls="pills-d" aria-selected="false">D</button>
                          </li>
                          <li className="nav-item item5" role="presentation">
                            <button disabled className={`nav-link ${sanitizedPropertyDetails.tags.some(tag => tag.label === 'E') ? 'active' : ''}`} id="pills-e-tab" data-bs-toggle="pill"
                              data-bs-target="#pills-e" type="button" role="tab"
                              aria-controls="pills-e" aria-selected="false">E</button>
                          </li>
                          <li className="nav-item item6" role="presentation">
                            <button disabled className={`nav-link ${sanitizedPropertyDetails.tags.some(tag => tag.label === 'F') ? 'active' : ''}`} id="pills-f-tab" data-bs-toggle="pill"
                              data-bs-target="#pills-f" type="button" role="tab"
                              aria-controls="pills-f" aria-selected="false">F</button>
                          </li>
                          <li className="nav-item item7" role="presentation">
                            <button disabled className={`nav-link ${sanitizedPropertyDetails.tags.some(tag => tag.label === 'G') ? 'active' : ''}`} id="pills-g-tab" data-bs-toggle="pill"
                              data-bs-target="#pills-g" type="button" role="tab"
                              aria-controls="pills-g" aria-selected="false">G</button>
                          </li>
                        </ul>
                        <div className="color-tab-inner">
                          <h3 className="cti-heading">
                            <span><img src="/assets/icons/Tag.svg" alt="/" /></span>Eco Friendly
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet consectetur. Ut urna pharetra sit vitae ut ac. Non cursus nec vel metus nibh rutrum accumsan egestas vel. In mi dui tempor amet. Imperdiet elit convallis viverra augue posuere amet pellentesque. Pellentesque molestie eget blandit purus pulvinar fames viverra interdum varius. Elit aliquet viverra gravida aliquet amet. Mollis sagittis purus sodales at diam lectus elit. Sed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="tab-pane fade" id="Sustainability" role="tabpanel" aria-labelledby="Sustainability-tab">
                  <div className="tab-inner4">

                    <div className="row  p-dr">
                      <h3> Sustainability</h3>
                      <div className="col-lg-12 performance-detail">
                        <div className="sus-box">
                          <h4 className="icon-head"><span><img src="/assets/icons/ih.svg" alt="/" /></span> Sustainability performance</h4>
                        </div>
                        <div className="sus-box-detail">
                          <h5>Insulation:</h5>
                          <p>Excellent thermal insulation using modern materials and energy-efficient design.Heating and cooling: Centralized heating system and reversible air conditioning powered by a geothermal heat pump</p>
                        </div>
                        <div className="sus-box-detail">
                          <h5>Lighting:</h5>
                          <p>LED lighting in common areas and apartments</p>
                        </div>
                        <div className="sus-box-detail">
                          <h5>Appliances:</h5>
                          <p>Apartments are equipped with energy-efficient appliances such as low-energy refrigerators and washing machines</p>
                        </div>
                        <div className="sus-box-detail">
                          <h5>Renewable energy</h5>
                          <p>The building has solar panels on the roof for renewable electricity generation.</p>
                        </div>
                        <div className="sus-box-detail">
                          <h5>Waste management:</h5>
                          <p>Implementation of selective sorting and composting systems to reduce landfill waste.</p>
                        </div>

                      </div>
                      <div className="col performance-detail ">
                        <h3 className="ec-head">Estimation of energy consumption and greenhouse gas emissions:</h3>
                        {sanitizedPropertyDetails.tags
                          .filter(tag => tag.section === "Energy consumption and gas emissions")
                          .map((tag, index) => (
                            <React.Fragment key={tag.tagId}>
                              <div className="col p-detail">
                                <p>{tag.label} <span>{tag.decimalValue} {tag.unit}</span></p>
                              </div>
                              {index % 3 === 2 && <div className="w-100"></div>}
                            </React.Fragment>
                          ))}
                      </div>
                      <div className="col-lg-12 accordian-cont ">
                        <h3 className="ec-head">Environmental features</h3>

                        <div className="accordion" id="accordionExample">
                          {sanitizedPropertyDetails.tags
                            .filter(tag => tag.section === "Environmental features")
                            .map((tag, index) => (
                              <div className="accordion-item" key={tag.tagId}>
                                <h2 className="accordion-header" id={`heading${index}`}>
                                  <button className={`accordion-button ${index === 0 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={index === 0} aria-controls={`collapse${index}`}>
                                    <span><img src={`/assets/icons/i${index + 1}.svg`} alt="/" /></span> {tag.label}
                                  </button>
                                </h2>
                                <div id={`collapse${index}`} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    {tag.stringValue}
                                  </div>
                                </div>
                              </div>
                            ))}
                        
                        </div>



                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="Place" role="tabpanel" aria-labelledby="Place-tab">
                                <div className="col-lg-12 map-cont ">
                                    <h3 className="map-head"> Place</h3>
                                    <p className="card-text"><span><img src="/assets/icons/loc.svg" alt="/" /></span>
                                        Orange street, United Kingdom 5012
                                    </p>
                                    <img className='map-pdpage' src="/assets/images/Map.png"alt="/" />
                                    <div className="tab-inner2">
                                        <h5 className='maps-btm-head'>Surrounded by</h5>
                                        <div className="row map-sb-row">
                                            <div className="col p-detail2">
                                    <div className='d-flex temp-il-div'><p> <img src="/assets/icons/r1.svg" alt="/"  /> Restaurant name</p> <span>2 Km away</span></div>
                                    <div className='d-flex temp-il-div'><p> <img src="/assets/icons/r2.svg" alt="/"  /> Train station </p> <span>2 Km away</span></div>
                                    <div className='d-flex temp-il-div'><p> <img src="/assets/icons/r3.svg" alt="/"  /> High school name </p> <span>2 Km away</span></div>
                                            </div>
                                            <div className="col p-detail2 ">
                                    <div className='d-flex temp-il-div'><p><img src="/assets/icons/r4.svg" alt="/"  /> Hotel name here </p> <span>2 Km away</span></div>
                                    <div className='d-flex temp-il-div'><p> <img src="/assets/icons/r5.svg" alt="/"  /> Coffee shop name</p> <span>2 Km away</span></div>
                                    <div className='d-flex temp-il-div'><p>  <img src="/assets/icons/r6.svg" alt="/"  /> Bank name goes here </p> <span>2 Km away</span></div>
                                                
                                                
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div> 

                {/* <div className="tab-pane fade" id="Place" role="tabpanel" aria-labelledby="Place-tab">
                  <div className="col-lg-12 map-cont">
                    <h3 className="map-head">Place</h3>
                    <p className="card-text">
                      <span><img src="/assets/icons/loc.svg" alt="/" /></span>
                      {address}
                    </p>
                    <LoadScript googleMapsApiKey="AIzaSyCWhL4ncu9oyBH3HEG3bRx8dVYdcy9ClH8" libraries={['places']}>
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '400px' }}
              center={{ lat: sanitizedPropertyDetails.address.latitude, lng: sanitizedPropertyDetails.address.longitude }}
              zoom={14}
              onLoad={map => setMap(map)}
            >
              <Marker position={{ lat: sanitizedPropertyDetails.address.latitude, lng: sanitizedPropertyDetails.address.longitude }} />
              {places.map((place, index) => (
                <Marker
                  key={index}
                  position={place.geometry.location}
                  onClick={() => setSelectedPlace(place)}
                />
              ))}
              {selectedPlace && (
                <InfoWindow
                  position={selectedPlace.geometry.location}
                  onCloseClick={() => setSelectedPlace(null)}
                >
                  <div>
                    <h4>{selectedPlace.name}</h4>
                    <p>{selectedPlace.vicinity}</p>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          </LoadScript>
                    <div className="tab-inner2">
                      <h5 className='maps-btm-head'>Surrounded by</h5>
                      <div className="row map-sb-row">
                        {places.map((place, index) => (
                          <div className="col p-detail2" key={index}>
                            <div className='d-flex temp-il-div'>
                              <p><img src="/assets/icons/loc.svg" alt="/" /> {place.name}</p>
                              <span>{place.vicinity}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="tab-pane fade" id="Finance" role="tabpanel" aria-labelledby="Finance-tab">
                  <div className="financ-tab-wrapper">
                    <h1 className="f-tab-head">Finance</h1>
                    <div className="tab-inner-calculator">
                      <h5 className='.pd-tr-hd'>Calculator</h5>
                      <div className="row">
                        <div className="col-lg-4 calc-tab text-center">
                          <h6 className="heading-box">Revenue/month</h6>
                          <p className="amount-box">+2,642 £</p>
                        </div>
                        <div className="col-lg-4 calc-tab2 text-center">
                          <h6 className="heading-box">Added value/ year</h6>
                          <p className="amount-box">+3,508 £</p>
                        </div>
                        <div className="col-lg-4 calc-tab3 text-center">
                          <h6 className="heading-box">Valuation / year</h6>
                          <p className="amount-box">+85,30 £</p>
                        </div>
                      </div>
                      <div className="row2">
                        <Range></Range>
                      </div>




                    </div>
                  </div>
                  <div className="container price-list-cont">
                    <div className="row">
                      <h2 className="h-pt">Acquisition</h2>
                      <div className="col-lg-12 d-flex main-cont-list">
                        <div className="col-lg-6 price-list">
                          <h3>Purchase price</h3>
                          <h3>TANNIT Rewards</h3>
                          <h3>Real estate agency fees</h3>
                          <h3>Legal fees</h3>
                          <h3>Money reserve (redevelopment)</h3>
                          <h3>Management fees</h3>
                          <h3>Taxes</h3>
                        </div>
                        <div className="col-lg-6 price-amount">
                          <h3> 300 000 £</h3>
                          <h3> 300 000 £</h3>
                          <h3> 300 000 £</h3>
                          <h3> 300 000 £</h3>
                          <h3> 300 000 £</h3>
                          <h3> 300 000 £</h3>
                          <h3> 300 000 £</h3>
                        </div>
                      </div>
                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-6 price-list-lh">
                          <h3>Total acquisition cost</h3>
                        </div>
                        <div className="col-lg-6 price-amount-lh">
                          <h3> 300 000 £</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container price-list-cont">
                    <div className="row">
                      <h2 className="h-pt">Revenue details</h2>
                      <div className="col-lg-12 d-flex main-cont-list">
                        <div className="col-lg-6 price-list">
                          <h3>Target rental yield</h3>
                          <h3>LTV (Loan To Value)</h3>
                          <h3>Collected rents</h3>
                          <h3>Loan repayment</h3>
                        </div>
                        <div className="col-lg-6 price-amount">
                          <h3>30%</h3>
                          <h3> 30%</h3>
                          <h3> 300 000 £</h3>
                          <h3> 300 000 £</h3>
                        </div>
                      </div>
                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-6 price-list-lh">
                          <h3>Total value</h3>
                        </div>
                        <div className="col-lg-6 price-amount-lh">
                          <h3> 300 000 £</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container price-list-cont">
                    <div className="row">
                      <h2 className="h-pt">Valuation</h2>
                      <div className="col-lg-12 d-flex main-cont-list">
                        <div className="col-lg-6 price-list">
                          <h3>Valuation of the property</h3>
                          <h3>Added value</h3>
                          <h3>Current silver reserve</h3>
                          <h3>Loan to repay</h3>
                          <h3>Taxes on resale</h3>
                        </div>
                        <div className="col-lg-6 price-amount">
                          <h3> 300 000 £</h3>
                          <h3> 300 000 £</h3>
                          <h3> 300 000 £</h3>
                          <h3> 300 000 £</h3>
                          <h3> 300 000 £</h3>
                        </div>
                      </div>
                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-6 price-list-lh">
                          <h3>Total value</h3>
                        </div>
                        <div className="col-lg-6 price-amount-lh">
                          <h3> 300 000 £</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-lg-12 g-cont d-flex">
                    <div className=" graph-sec d-flex">
                      <h2 className="h-pt2">Evolution of the value of TANNIT</h2>
                      <h1>10,20 £</h1>
                      <p>Current value</p>
                      <img src="/assets/images/chart2.png" alt="/" />
                    </div>
                  </div>
                  <div className="container-lg-12 g-cont d-flex">
                    <div className=" graph-sec d-flex">
                      <h2 className="h-pt2">Monthly income per TANNIT</h2>
                      <h1>0.05 £</h1>
                      <p>Cible (Soit 5.53% annuel)</p>
                      <img src="/assets/images/chart3.png" alt="/" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>






          <div className="col-sm-4 p-4 cards-div d-flex">
            <div className="left-card1 d-flex">
              <h3>Amount of TANNIT</h3>
              <h1>10,20 £</h1>
              <p>Current value</p>
              <img src="/assets/images/Line Chart.png" alt="/" />
            </div>
            <div className="left-card1 d-flex">
              <div>
                <div className="card-inner">
                  <div className="amount">{price} £</div>
                  <div className="remain">
                    <img src="/assets/icons/Tannit coin.svg" alt="/" />
                    {remainingInvested} remaining
                  </div>
                </div>
                <div className="card-inner2">
                  <div className="amount-text">Converted to: {priceInTannit} Tannits</div>
                  <div className="investor">
                    <img src="/assets/icons/Icon2.svg" alt="/" />
                    {numberOfInvestors} Investors
                  </div>
                </div>
                <div className="progress">
                  <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div className="icon-bar pt-3">
                  <div className="inline-svg-icons d-flex">
                    <button className="inline-svg-icons-btn1"><img src="/assets/icons-2/Tag1.svg" alt="/" /></button>
                    <button className="inline-svg-icons-btn1"><img src="/assets/icons-2/Tag2.svg" alt="/" /></button>
                    <button className="inline-svg-icons-btn1"><img src="/assets/icons-2/Tag3.svg" alt="/" /></button>
                    <button className="inline-svg-icons-btn1"><img src="/assets/icons-2/Tag4.svg" alt="/" /></button>
                    <button className="inline-svg-icons-btn1"><img src="/assets/icons-2/Tag5.svg" alt="/" /></button>
                  </div>
                </div>
                <div className="card-inner3 my-3 d-flex flex-direction-column">
                  <div className="profit d-flex flex-direction-column">
                    Profitability <img src="/assets/icons/Instance tooltip.png" alt="/" />
                    <p>{profitabilityTag ? `${profitabilityTag.decimalValue}${profitabilityTag.unit}` : 'N/A'}</p>
                  </div>
                  <div className="returned d-flex flex-direction-column">
                    Returned <img src="/assets/icons/Instance tooltip.png" alt="/" />
                    <p>{returnedTag ? `${returnedTag.decimalValue}${returnedTag.unit}` : 'N/A'}</p>
                  </div>
                </div>
                <div className="card-body comp-body">
                  <Link to={`/purchaseconfirm?propertyId=${propertyId}`} className={`view-btn ${remainingInvested === 0 ? 'disabled' : ''}`}>
                    Invest <i className="fa-solid fa-arrow-right"></i>
                  </Link>                
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


    </div>
  );

};


export default PropertyDetail;