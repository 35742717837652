import {React, useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddMoney = () => {
  const [selectedTannits, setSelectedTannits] = useState(null);
  const [customTannits, setCustomTannits] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('Paypal'); // default payment method
  const apiUrl = process.env.REACT_APP_API_URL;
  const user = useSelector(state => state.auth.user);
  const { id: userId } = user.claims;
  const navigate = useNavigate();
  const handleRadioChange = (value) => {
    setSelectedTannits(value);
    setCustomTannits(''); // Clear custom input if a radio button is selected
  };

  const handleCustomChange = (e) => {
    setSelectedTannits(null); // Clear radio selection if custom input is used
    setCustomTannits(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tannitsTopped = selectedTannits ? selectedTannits : customTannits;
  
    const transaction = {
      TannitsTopped: parseInt(tannitsTopped), 
      Price: parseFloat(tannitsTopped) * 10, 
      CurrencyId: 1,
      Description: 'Top-up transaction',
      InvestorId: userId, 
      CreatedAt: new Date().toISOString(),
      Vested: false, 
    };
    try {
      if (paymentMethod === 'Paypal' || paymentMethod === 'Stripe') {
      const response = await axios.post(`${apiUrl}/Transaction/AddTopupTransaction`, transaction);
      if (response.status === 201 || response.status === 200) {
          navigate('/topupSuccess');
        } 
       else {
        console.error('Failed to add transaction:', response.data);
      }
    }
      else if (paymentMethod === 'GoCardless') {
        navigate('/topupFailer');
      }
    } catch (error) {
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
        navigate('/topupFailer');
      } else if (error.request) {
        console.error('Error request:', error.request);
        navigate('/topupFailer');
      } else {
        console.error('Error message:', error.message);
        navigate('/topupFailer');
      }
    }
  };
  useEffect(() => {
    document.title = 'Add money'; // Set the page title on component mount
    return () => {
      document.title = 'Tannit'; // Reset the page title when component unmounts
    };
  }, []); // Empty dependency array ensures this effect runs only on mount and unmount
  return (
    <div className="App">
      <section className="main-cont-comp" style={{ maxWidth: '800px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 main-comp-sec">
              <h3>Add Money to Your Pot: Boost Your Savings with Contributions</h3>
              <form onSubmit={handleSubmit}>
                <div className="col-12">
                  <div className="mr-1 row d-flex">
                    <div className="form-radio-check col-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        onChange={() => handleRadioChange(120)}
                        checked={selectedTannits === 120}
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault1"></label>
                      <h4>
                        <span>
                          <img src="assets/icons/Tannit coin.svg" alt="" />
                        </span>
                        120
                      </h4>
                      <p>1200.00£</p>
                    </div>
                    <div className="form-radio-check col">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        onChange={() => handleRadioChange(320)}
                        checked={selectedTannits === 320}
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault2"></label>
                      <h4>
                        <span>
                          <img src="assets/icons/Tannit coin.svg" alt="" />
                        </span>
                        320
                      </h4>
                      <p>3200.00£</p>
                    </div>
                    <div className="form-radio-check col-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault3"
                        onChange={() => handleRadioChange(1500)}
                        checked={selectedTannits === 1500}
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault3"></label>
                      <h4>
                        <span>
                          <img src="assets/icons/Tannit coin.svg" alt="" />
                        </span>
                        1500
                      </h4>
                      <p>15000.00£</p>
                    </div>
                    <div className="col-12 not-sec">
                      <h5>Custom amount</h5>
                      <p>Amount of TANNIT desired</p>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <img src="assets/icons/Tannit coin.svg" alt="" />
                          </span>
                        </div>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Number of TANNIT"
                            value={customTannits}
                            onChange={(e) => {
                              // Allow only positive numbers
                              const value = e.target.value;
                              if (value === '' || /^[1-9]\d*$/.test(value)) {
                                setCustomTannits(value);
                              }
                            }}
                            min="1" // Ensure the minimum value is 1
                            aria-label="Amount (to the nearest dollar)"
                          />
                        <div className="input-group-append">
                          <span className="input-group-text">= {customTannits * 10}£</span>
                        </div>
                      </div>
                      <label htmlFor="basic-note" className="form-label note">
                        Note: 1 TANNIT = 10£
                      </label>
                    </div>
                    <div className="col-12 new-radio-2">
                      <h3>Payment method</h3>
                      <div className="mr-1 row d-flex">
                        <div className="form-radio-check2 col-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="flexRadioDefault2_1"
                            onChange={() => setPaymentMethod('Stripe')}
                            checked={paymentMethod === 'Stripe'}
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault2_1"></label>
                          <h4>
                            <span>
                              <img src="assets/icons/stripe.svg" alt="" />
                            </span>
                            Stripe
                          </h4>
                        </div>
                        <div className="form-radio-check2 col">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="flexRadioDefault2_2"
                            onChange={() => setPaymentMethod('Paypal')}
                            checked={paymentMethod === 'Paypal'}
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault2_2"></label>
                          <h4>
                            <span>
                              <img src="assets/icons/paypal.svg" alt="" />
                            </span>
                            Paypal
                          </h4>
                        </div>
                        <div className="form-radio-check2 col-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="flexRadioDefault2_3"
                            onChange={() => setPaymentMethod('GoCardless')}
                            checked={paymentMethod === 'GoCardless'}
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault2_3"></label>
                          <h4>
                            <span>
                              <img src="assets/icons/cardless.svg" alt="" />
                            </span>
                            GoCardless
                          </h4>
                        </div>
                        <div className="verification-btns d-flex">
                          <button type="button" className="btn radio-btn-1 btn-primary">
                            Cancel
                          </button>
                          <button type="submit" className="btn radio-btn-2 btn-primary">
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddMoney;
