import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../../component/dashheader";

function MarketPlaceTwo() {
  const [propertiesGrouped, setPropertiesGrouped] = useState({});
  const base_Url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Fetch data from the API when the component loads
    axios.get(base_Url + '/Marketplace', {
      headers: { 'Accept': 'text/plain' },
    })
      .then((response) => {
        if (response.data.status.code === 1) {
          // Assuming the response data is structured as an array of arrays
          const properties = response.data.data[0];

          // Group the properties by `propertyId`
          const grouped = properties.reduce((acc, property) => {
            if (!acc[property.propertyId]) {
              acc[property.propertyId] = [];
            }
            acc[property.propertyId].push(property);
            return acc;
          }, {});

          setPropertiesGrouped(grouped);
        }
      })
      .catch((error) => {
        console.error('Error fetching properties:', error);
      });
  }, [base_Url]);

  // Function to handle the click on the "Buy" button
  const handleBuyClick = (property) => {
    // Store data in localStorage
    localStorage.setItem('purchaseDetails', JSON.stringify({
      ForSaleId: property.id,
      InvestorId: property.investorId,
      PropertyId: property.propertyId,
      Shares: property.amountTannit,
      sellerId: property.investorId,
      SellerFullName: property.sellerFullName,
      AmountTannit: property.askingPrice,
    }));
    console.log("Data stored in localStorage:", {
        ForSaleId: property.id,
        InvestorId: property.investorId,
        PropertyId: property.propertyId,
        Shares: property.amountTannit,
        sellerId: property.investorId,
        SellerFullName: property.sellerFullName,
        AmountTannit: property.askingPrice,
    });

    // Redirect to the buy property page (optional)
    window.location.href = '/buyproperty';
  };

  return (
    <div className="App">
      <Header />
      <section>
        <div className="container-fluid artical-cont" style={{ maxWidth: '1440px' }}>
          {Object.keys(propertiesGrouped).length > 0 ? (
            Object.entries(propertiesGrouped).map(([propertyId, propertyList]) => (
              <div key={propertyId} className="container main-mpv2-cont mt-3">
                <div className="row mvp-r1">
                  <div className="col-2 p-0">
                    <img src="assets/images/mv2.png" alt="Property" />
                  </div>
                  <div className="col-8 mv2-1sthead py-0 px-3">
                    <h3>{propertyList[0].property.propertyName}</h3>
                    <p className="card-text">
                      <span><img src="assets/icons/gb 1.svg" className='me-2' alt="Location" /></span>
                      {propertyList[0].property.address.streetAddress + ', ' + propertyList[0].property.address.city + ' ' + propertyList[0].property.address.postalCode}
                    </p>
                  </div>
                  <div className="col mv2-btn">
                    <button>
                      Property details <span><img src="assets/icons/Fontexit2.svg" alt="Details" /></span>
                    </button>
                  </div>
                </div>

                <div className="row mv2-table">
                  <div className="col mtable-mconl">
                    <table className="table mv2-tab-main">
                      <thead>
                        <tr>
                          <th scope="col">Date Joined</th>
                          <th scope="col">Seller</th>
                          <th scope="col">Earnings</th>
                          <th scope="col">Asking Price</th>
                          <th scope="col">For Sale</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {propertyList.map((property) => (
                          <tr key={property.id}>
                            <td>{new Date(property.timeStamp).toLocaleDateString()}</td>
                            <td className="img-text-td">
                              <span><img src="assets/images/Ellipse 231.png" alt="Seller" /></span> {property.sellerFullName}
                            </td>
                            <td>
                              {property.sellingPercentage >= 0 ? <i class="fa fa-arrow-up me-2 text-success" aria-hidden="true"></i> : <i class="fa fa-arrow-down me-2 text-danger" aria-hidden="true"></i>}
                              {property.sellingPercentage >= 0 ? `+${property.sellingPercentage}%` : `${property.sellingPercentage}%`}
                            </td>
                            <td>{property.askingPrice}£</td>
                            <td className="img-text-td2">
                              {property.amountTannit} <span><img src="assets/images/Tannit coin.png" alt="Tannit Coin" /></span>
                            </td>
                            <td className="img-text-td2">
                              {/* Modified Buy button with event handler */}
                              <button
                                className="view-btn px-0"
                                onClick={() => handleBuyClick(property)}
                              >
                                Buy <i className="fa-solid fa-arrow-up-right-from-square"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No properties available</div>
          )}
        </div>
      </section>
    </div>
  );
}

export default MarketPlaceTwo;
